import React, { useEffect, useRef, useState } from 'react';
import './login.css'
import LiveSpoofDetection from '@sensityai/live-spoof-detection';
import { Link } from 'react-router-dom';


const Login = () => {
  const [timeOut, setTimeOut] = useState(false);
  const webcam = useRef(null);
  const liveness: any = useRef();
  let spoof: any;

  const detectFace = async () => {
    setTimeOut(false)
    const webcamElement: any = webcam.current;
    const livenessTask = liveness.current;
    if (!spoof) {
      spoof = new LiveSpoofDetection(webcamElement);
      spoof.startVideo().then((videoStarted: any) => {
        if (videoStarted) {
          spoof.startActiveLiveness(livenessTask);
          const interval = setInterval(() => {
            if (livenessTask.innerHTML.trim() === 'Liveness is done') {
              spoof.takeSelfie().then((selfie: any) => {
                console.log("Selfie taken", selfie);
              });
              clearInterval(interval);
              setTimeout(() => {
                // spoof.closeVideo();
                 window.close();
                 window.parent.postMessage({ type: 'Login Done', data: null }, '*');
                 }, 2000);
            } else if (livenessTask.innerHTML.trim() === 'Session timed out. Please restart liveness detection.') {
              setTimeOut(true)
              clearInterval(interval);
            }
          }, 1000);
          return () => clearInterval(interval);
        } else {
          console.log("Video failed to load");
        }
      })
    }
  };

  useEffect(() => {
    detectFace();
  }, []);



  return (
    <div className="pages">
        <h2>Log In</h2>
        <p>Please turn on your camera and place your head in the circle.</p>
        <form>
          <div className='faceContainer'>
            <div className="video-container">
              <video
                muted
                ref={webcam}
                autoPlay
                className="webcamVideo"
                playsInline
                width="400"
                height="350"
                style={{ transform: "scaleX(-1)" }}
              ></video>
            </div>
          </div>
          <div ref={liveness} id='livenessTask'></div>
          </form>
          {timeOut && (
            <button onClick={detectFace} className='try-again'><span className='button-text'>Try Again</span></button>
          )}
          <p style={{ fontSize: "14px" }}>Need Help?</p>
          <p style={{ fontWeight: "700px", fontSize: "14px" }}>By logging in, you're agreeing to our <b>Terms & Conditions</b> and <b>Privacy Policy.</b><span style={{ color: "#FF2D55" }}>*</span></p>
          <Link  to="/kyc-process">
          <button type="button"  className="button" style={{ backgroundColor: "#CDE8E6" }}>
            <span className='button-text' style={{ color: "#1C5257" }}>Don't have an account? Register</span>
          </button>
        </Link>
    </div>
  );
};

export default Login;