// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.login-text{
    font-family:neue haas grotesk display pro;
    font-size: 16px !important;
    font-weight: 700px;
    color: #FFFFFF;
    letter-spacing: 1px;
    line-height: 20.16px;
}
/* .webcam-video{
    width: 435px;
    height: 229px;
    left: -80px;
    position: relative;
} */

`, "",{"version":3,"sources":["webpack://./src/components/login.css"],"names":[],"mappings":";AACA;IACI,yCAAyC;IACzC,0BAA0B;IAC1B,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,oBAAoB;AACxB;AACA;;;;;GAKG","sourcesContent":["\n.login-text{\n    font-family:neue haas grotesk display pro;\n    font-size: 16px !important;\n    font-weight: 700px;\n    color: #FFFFFF;\n    letter-spacing: 1px;\n    line-height: 20.16px;\n}\n/* .webcam-video{\n    width: 435px;\n    height: 229px;\n    left: -80px;\n    position: relative;\n} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
