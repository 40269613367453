import React, { useCallback, useEffect, useRef, useState } from 'react';



const ThankuPage = ({ setIsBlurred }:any) => {

    return (
        <div className="pages">
        <div className="thanku-page">
        <div className='success-image'>
          <img src='/assets/verified.png' alt="verified" className="verified-image" />
        </div>
        <h2 style={{width:"500px"}}>Thank you for using Voltox!</h2>
        <p style={{ marginLeft: "96px"}}> You have successfully login!</p>
      </div>
      </div>
    )
}

export default ThankuPage;