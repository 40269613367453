import React, {
  useReducer,
  useRef,
  useCallback,
  useState,
  useEffect,
} from "react";
import axios from "axios";
import Webcam from "react-webcam";
import "./age-estimation.css";
import { Modal } from "react-bootstrap";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import QRCode from "react-qr-code";
import socketIOClient from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { MdFileUpload } from "react-icons/md";

declare global {
  interface Window {
    google: {
      translate: {
        TranslateElement: {
          new (
            options: { pageLanguage: string; autoDisplay: boolean },
            elementId: string
          ): void;
        };
      };
    };
    googleTranslateElementInit(): void;
  }
}

const initialState = {
  apiSuccess: -1,
  isLoading: false,
  isScanDocument: 0,
  scanDocumentPage: 0,
  isFaceScan: 1,
  isSquare: 0,
  ageRange: "",
};

let ageEstimationData: any = {};

function reducer(state: any, action: any) {
  switch (action.type) {
    case "SET_API_SUCCESS":
      return { ...state, apiSuccess: action.payload };
    case "SET_AGE_RANGE":
      return { ...state, ageRange: action.payload };
    // case "SET_ERROR":
    //   return { ...state, error: action.payload };
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "SCAN_DOCUMENT":
      return { ...state, isScanDocument: action.payload };
    case "SCAN_DOCUMENT_PAGE":
      return { ...state, scanDocumentPage: action.payload };
    case "FACE_SCAN":
      return { ...state, isFaceScan: action.payload };
    case "SQUARE":
      return { ...state, isSquare: action.payload };
    default:
      return state;
  }
}
const AgeEstimation = () => {
  const [countdown, setCountdown] = useState(0);
  const [capturedImage, setCapturedImage] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const urlParams = new URLSearchParams(window.location.search);
  const webcamRef: any = useRef(null);
  const [hasCameraPermission, setHasCameraPermission] = useState(false);
  const [hasQRCode, setHasQrCode] = useState(false);
  const [QRCodeUrl, setQRCodeUrl] = useState("");
  const [fileError, setFileError] = useState<string | null>(null);
  const [captureFile, setCaptureFile] = useState<File | null>(null);
  const socket = socketIOClient("https://api.voltox.ai");

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setPreview(URL.createObjectURL(file));
    if (file.size > 1024 * 1024) {
      // file size is in bytes
      setFileError("File size cannot exceed 1024 KB");
      return;
    }

    const allowedExtensions = [
      "pdf",
      "jpg",
      "png",
      "jpeg",
      "bmp",
      "gif",
      "tif",
      "tiff",
      "webp",
    ];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      setPreview(null);
      setFileError(
        "Invalid file type. Only .pdf, .jpg, .png, .jpeg, .bmp, .gif, .tif, .tiff, .webp files are allowed"
      );
      return;
    }

    setFileError(null); // clear any previous error
  };

  const handleCameraStart = () => {
    setHasCameraPermission(true);
  };
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props} className="custom-tooltip">
      support@voltox.ai
    </Tooltip>
  );
  const postAgeTransaction = async (body: any, apiURl: any, headers?: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${apiURl}`,
        body,
        { headers }
      );
      return response.data;
    } catch (error) {
      dispatch({
        type: "SET_ERROR",
        payload: "An error occurred while processing your request.",
      });
    }
  };

  const generateQRCode = () => {
    setHasQrCode(true);
    const randomString = uuidv4();
    setQRCodeUrl(
      `${process.env.REACT_APP_FACE_APP_URL!}?uniqueQRCode=${randomString}`
    );
    console.log(
      "QRCodeUrl",
      `${process.env.REACT_APP_FACE_APP_URL!}?uniqueQRCode=${randomString}`
    );
    localStorage.setItem("QRCode", randomString);
    socket.emit("login", randomString);
  };

  const handleApiResponse = (data: any) => {
    dispatch({ type: "SET_API_RESPONSE", payload: data.data });
    dispatch({ type: "SET_API_SUCCESS", payload: data.settings.success });
  };

  const capture = useCallback(() => {
    setCountdown(3);
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval);
          const token: any =
            urlParams.get("token") || "0c18c0a4-a5c4-4945-94f3-b30aaed884f3";
          const base64Image = webcamRef.current.getScreenshot();
          setCapturedImage(base64Image);
          dispatch({ type: "SET_LOADING", payload: true });
          (async () => {
            const fetchResponse = await fetch(base64Image);
            const blob = await fetchResponse.blob();
            const file = new File([blob], "filename.jpeg", {
              type: "image/jpeg",
            });
            const formData = new FormData();
            formData.append("file", file);
            formData.append("token", token);
            setCaptureFile(file);
            ageEstimationData = await postAgeTransaction(
              formData,
              "/user/age-transaction",
              {
                "Content-Type": "multipart/form-data",
              }
            );
            if (ageEstimationData && ageEstimationData.settings) {
              if (
                ageEstimationData.data &&
                Object.keys(ageEstimationData.data).length > 0
              ) {
                dispatch({ type: "SET_LOADING", payload: false });
                if (ageEstimationData.data.age < 18) {
                  const { age_txt } = ageEstimationData.data;
                  const ageRange = age_txt.match(/\d+/g).map(Number);
                  const [start, end] = ageEstimationData.data.ageRange
                    .split("-")
                    .map(Number);
                  const ageCheck = Array.from(
                    { length: end - start + 1 },
                    (_, i) => start + i
                  );
                  if (ageCheck.includes(ageRange[1])) {
                    dispatch({ type: "SCAN_DOCUMENT", payload: 1 });
                    ageEstimationData = {
                      settings: {
                        ...ageEstimationData.settings,
                        success: 0,
                        message:
                          "You are under 18 years of age. Please scan your document for verification.",
                      },
                      data: {
                        ...ageEstimationData.data,
                      },
                    };
                  } else {
                    ageEstimationData = {
                      settings: {
                        ...ageEstimationData.settings,
                        success: 0,
                        message: "You are under 18 years of age",
                      },
                      data: {
                        ...ageEstimationData.data,
                      },
                    };
                    const webCamCode = localStorage.getItem("WebCamQRCode");
                    if (webCamCode) {
                      const data = {
                        uniqueQRCode: webCamCode,
                        ageEstimationData,
                      };
                      socket.emit("ageEstimationData", data);
                    }
                  }
                }
                const webCamCode = localStorage.getItem("WebCamQRCode");
                if (webCamCode) {
                  const data = {
                    uniqueQRCode: webCamCode,
                    ageEstimationData,
                  };
                  socket.emit("ageEstimationData", data);
                }
                dispatch({
                  type: "SET_AGE_RANGE",
                  payload: ageEstimationData.data.age_txt,
                });
                handleApiResponse(ageEstimationData);
              } else if (
                ageEstimationData &&
                ageEstimationData.settings &&
                !ageEstimationData.settings.success &&
                ageEstimationData.settings.message ===
                  "You have reached to face verification limit"
              ) {
                dispatch({
                  type: "SET_ERROR",
                  payload: "Server Down! Please try again later.",
                });
                dispatch({ type: "SET_LOADING", payload: false }); // Move this line here
                handleApiResponse(ageEstimationData);

                const webCamCode = localStorage.getItem("WebCamQRCode");
                if (webCamCode) {
                  const data = {
                    uniqueQRCode: webCamCode,
                    ageEstimationData,
                  };
                  socket.emit("ageEstimationData", data);
                }
              }
            }
            dispatch({ type: "SET_LOADING", payload: false });
          })();
        }
        return prevCountdown - 1;
      });
    }, 1000);
  }, []);

  const scanDocument = () => {
    dispatch({ type: "SCAN_DOCUMENT_PAGE", payload: 1 });
    dispatch({ type: "SET_API_SUCCESS", payload: -1 });
    dispatch({ type: "FACE_SCAN", payload: 0 });
    // dispatch({ type: "SQUARE", payload: 1 });
    setCapturedImage(null);
  };

  const handleScanDocument = async () => {
    if (!selectedFile) {
      return;
    }
    const token: any =
      urlParams.get("token") || "0c18c0a4-a5c4-4945-94f3-b30aaed884f3";
    dispatch({ type: "SET_LOADING", payload: true });
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("token", token);
    const previousData = ageEstimationData;
    const faceData = new FormData();
    faceData.append("idFrame", selectedFile);
    faceData.append("token", token);
    if (captureFile) {
      faceData.append("faceFrame", captureFile);
    }
    ageEstimationData = await postAgeTransaction(
      faceData,
      "/user/face-search",
      {
        "Content-Type": "multipart/form-data",
      }
    );

    console.log(ageEstimationData);

   
    if(ageEstimationData && ageEstimationData.data && ageEstimationData.data.similarity_status){
      ageEstimationData = await postAgeTransaction(
        formData,
        "/user/ocr-transaction",
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (ageEstimationData && ageEstimationData.settings) {
        if (
          ageEstimationData.data &&
          ageEstimationData.settings.success &&
          Object.keys(ageEstimationData.data).length > 0
        ) {
          // setTimeout(()=>{})
          ageEstimationData = await postAgeTransaction(
            {
              reference_id: ageEstimationData.data.reference_id,
            },
            "/user/ocr-result"
          );
          if (ageEstimationData && ageEstimationData.settings) {
            if (
              ageEstimationData.data &&
              Object.keys(ageEstimationData.data).length > 0
            ) {
              const dateMatch = ageEstimationData.data.ocr_results.date_of_birth;
              if (dateMatch) {
                const date = dateMatch;
                const birthDate = new Date(date);
                const today = new Date();
                let age = today.getFullYear() - birthDate.getFullYear();
                const m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                  age--;
                }
                if (age <= 18) {
                  ageEstimationData = {
                    settings: {
                      ...ageEstimationData.settings,
                      success: 0,
                      message: "You are under 18 years of age",
                    },
                    data: {
                      reference_id: previousData.reference_id || "",
                      is_age_detected: 1,
                      age,
                    },
                  };
                } else {
                  ageEstimationData = {
                    settings: {
                      ...ageEstimationData.settings,
                      success: 1,
                      message: "You are above 18 years of age",
                    },
                    data: {
                      reference_id: previousData.reference_id || "",
                      is_age_detected: 1,
                      age,
                    },
                  };
                }
                dispatch({ type: "SET_LOADING", payload: false });
                dispatch({ type: "SCAN_DOCUMENT_PAGE", payload: 0 });
                handleApiResponse(ageEstimationData);
              } else {
                dispatch({ type: "SCAN_DOCUMENT_PAGE", payload: 1 });
                dispatch({ type: "SET_API_SUCCESS", payload: -1 });
                setFileError("Please upload valid document");
              }
            } else if (
              ageEstimationData &&
              ageEstimationData.settings &&
              !ageEstimationData.settings.success
            ) {
              dispatch({
                type: "SET_ERROR",
                payload: "Server Down! Please try again later.",
              });
              dispatch({ type: "SET_LOADING", payload: false }); // Move this line here
              handleApiResponse(ageEstimationData);
            }
          }
        } else if (
          ageEstimationData &&
          ageEstimationData.settings &&
          !ageEstimationData.settings.success &&
          ageEstimationData.settings.message ===
            "You have reached to document ocr verification limit"
        ) {
          handleApiResponse(ageEstimationData);
          dispatch({
            type: "SET_ERROR",
            payload: "Server Down! Please try again later.",
          });
          dispatch({ type: "SET_LOADING", payload: false }); // Move this line here
          dispatch({ type: "SCAN_DOCUMENT_PAGE", payload: 0 });
          dispatch({ type: "SCAN_DOCUMENT", payload: 0 });
        }
      }
    }
    else{
      dispatch({
        type: "SET_ERROR",
        payload: "Face not matched.",
      });
      dispatch({ type: "SET_LOADING", payload: false }); // Move this line here

      ageEstimationData = {
        settings: {
          ...ageEstimationData.settings,
          success: 0,
          message: "Scaned and Id face is not matched.",
        },
        data:ageEstimationData.data
      };
          dispatch({ type: "SCAN_DOCUMENT_PAGE", payload: 0 });
      handleApiResponse(ageEstimationData);
    }
  };

  const handleClose = () => {
    if (ageEstimationData && Object.keys(ageEstimationData).length > 0) {
      window.parent.postMessage(
        { type: "age-estimation", data: ageEstimationData },
        "*"
      );
    }
  };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    let addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    const params = new URLSearchParams(window.location.search);
    const uniqueQRCode = params.get("uniqueQRCode");
    if (uniqueQRCode) {
      localStorage.setItem("WebCamQRCode", uniqueQRCode);
      socket.emit("login", uniqueQRCode);
    }
    socket.on("ageEstimationData", (message) => {
      const localQRCode = localStorage.getItem("QRCode");
      if (localQRCode && localQRCode === message.uniqueQRCode) {
        setHasQrCode(false);
        handleApiResponse(message.ageEstimationData);
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="age-estimation-modal"
    >
      <Modal.Body>
        <div className="container">
          <div className="mobile-hedaer">
            <img src="/assets/logo.svg" alt="login" className="mobile-logo" />
          </div>
          <div className="bg-white h-auto age-row">
            <div className="p-0 left-side-image">
              <img src="/assets/logo-bg.svg" alt="login" className="logo-bg" />
              <img src="/assets/logo.svg" alt="login" className="logo-icon" />
            </div>
            <div className="p-0 right-side age-estimation">
              <div className="pages">
                <div className="content">
                  {state.apiSuccess === 0 && (
                    <div
                      className="closeButton btn-close"
                      onClick={handleClose}
                    ></div>
                  )}

                  <h2>Age Estimation</h2>
                  {countdown <= 0 &&
                    state.apiSuccess === -1 &&
                    state.isFaceScan === 1 &&
                    !capturedImage && (
                      <p>
                        Please turn on your camera and place your head in the
                        circle.
                      </p>
                    )}
                  {(countdown > 0 || capturedImage) &&
                    state.apiSuccess !== 1 &&
                    state.apiSuccess !== 0 && (
                      <p>Maintain a straight posture facing your device.</p>
                    )}

                  {countdown <= 0 &&
                    state.scanDocumentPage === 1 &&
                    state.apiSuccess === -1 &&
                    !capturedImage && (
                      // <p>
                      //   Position your document fully in the camera frame and
                      //   ensure good lighting.
                      // </p>

                      <p>Please upload your document.</p>
                    )}

                  {state.apiSuccess === 1 && (
                    <p>Authorization has been successfully completed.</p>
                  )}

                  {state.apiSuccess === 0 && (
                    <p>An authorization has failed due to an error.</p>
                  )}
                  <div
                    className={`${
                      state.scanDocumentPage ? "" : "faceContainer"
                    } ${
                      state.apiSuccess === 1
                        ? "success"
                        : state.apiSuccess === 0
                        ? "error"
                        : ""
                    }`}
                    style={{
                      // borderRadius:
                      //   state.isSquare === 1 || hasQRCode ? "0%" : "",
                      borderRadius: hasQRCode ? "0%" : "",
                      border: hasQRCode ? "none" : "",
                      maxWidth: hasQRCode ? "100%" : "",
                      height: hasQRCode ? "100%" : "",
                    }}
                  >
                    {state.apiSuccess === 1 ? (
                      <img
                        src="/assets/success-image.svg"
                        alt="success-img"
                        className="success-img"
                      />
                    ) : state.apiSuccess === 0 ? (
                      <img
                        src="/assets/error-image.svg"
                        alt="login"
                        className="error-img"
                      />
                    ) : capturedImage ? (
                      <div className="imageContainer">
                        <img
                          src={capturedImage}
                          alt="Captured"
                          className="webcamVideo"
                        />
                        {state.isLoading && (
                          <div className="loader">
                            <div></div>
                          </div>
                        )}
                      </div>
                    ) : state.isFaceScan ? (
                      <div className="webcamContainer">
                        <Webcam
                          audio={false}
                          autoPlay
                          className={`webcamVideo ${
                            hasCameraPermission ? "" : "hidden"
                          }`}
                          playsInline
                          width="400"
                          height="350"
                          style={{ transform: "scaleX(-1)" }}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          onUserMedia={handleCameraStart}
                        />
                        {!hasCameraPermission &&
                          (!hasQRCode ? (
                            <img
                              className="defaultImage"
                              src="/assets/default-image.svg"
                              alt="Default"
                            />
                          ) : (
                            <QRCode value={QRCodeUrl} />
                          ))}
                      </div>
                    ) : (
                      <div>
                        <label htmlFor="fileUpload">
                          <MdFileUpload
                            className="mr-2"
                            style={{
                              fontSize: "24px",
                              width: "32px",
                              height: "32px",
                              cursor: "pointer",
                            }}
                          />
                        </label>
                        <input
                          id="fileUpload"
                          type="file"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        {preview && (
                          <div className="imageContainer">
                            <img
                              src={preview}
                              alt="File preview"
                              style={{ height: "100px" }}
                            />
                            {state.isLoading && (
                              <div className="loader">
                                <div></div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {fileError && <div className="file-error">{fileError}</div>}
                    {countdown > 0 && (
                      <div className="countdown">{countdown}</div>
                    )}
                    {!urlParams.get("token") &&
                      (state.ageRange !== null || state.ageRange !== "") &&
                      state.isScanDocument === 0 && (
                        <div
                          className="countdown"
                          style={{ fontSize: "16px", color: "rgb(28, 82, 87)" }}
                        >
                          {state.ageRange}
                        </div>
                      )}
                  </div>
                  {countdown <= 0 &&
                    state.apiSuccess === -1 &&
                    !capturedImage &&
                    state.isFaceScan === 1 &&
                    hasCameraPermission && (
                      <button
                        type="button"
                        className="button"
                        disabled={state.isLoading}
                        onClick={capture}
                        style={{
                          backgroundColor: state.isLoading
                            ? "#77979A"
                            : "#1C5257",
                        }}
                      >
                        <span
                          className="button-text"
                          style={{
                            opacity: state.isLoading ? "0.5" : "1",
                          }}
                        >
                          Scan Face
                        </span>
                      </button>
                    )}

                  {countdown <= 0 &&
                    state.apiSuccess === -1 &&
                    !capturedImage &&
                    state.isFaceScan === 1 &&
                    !hasCameraPermission &&
                    !QRCodeUrl && (
                      <button
                        type="button"
                        className="button"
                        disabled={state.isLoading}
                        onClick={generateQRCode}
                        style={{
                          backgroundColor: state.isLoading
                            ? "#77979A"
                            : "#1C5257",
                        }}
                      >
                        <span
                          className="button-text"
                          style={{
                            opacity: state.isLoading ? "0.5" : "1",
                          }}
                        >
                          Generate QR Code
                        </span>
                      </button>
                    )}

                  {countdown <= 0 &&
                    state.apiSuccess === -1 &&
                    !capturedImage &&
                    state.scanDocumentPage === 1 && (
                      <button
                        type="button"
                        className="button"
                        disabled={state.isLoading || !selectedFile}
                        onClick={handleScanDocument}
                        style={{
                          backgroundColor:
                            state.isLoading || !selectedFile
                              ? "#77979A"
                              : "#1C5257",
                        }}
                      >
                        <span
                          className="button-text"
                          style={{
                            opacity:
                              state.isLoading || !selectedFile ? "0.5" : "1",
                          }}
                        >
                          Submit
                        </span>
                      </button>
                    )}

                  {(countdown > 0 || capturedImage) &&
                    state.apiSuccess !== 1 &&
                    state.apiSuccess !== 0 && (
                      <p className="scan">Scanning...</p>
                    )}

                  {/* {state.error && (
                    <p style={{ color: "#FF9089" }}>{state.error}</p>
                  )} */}
                  {state.apiSuccess === 0 && (
                    <p style={{ color: "#FF9089", fontSize: "24px" }}>
                      Denied!
                    </p>
                  )}
                  {state.apiSuccess === 0 && (
                    <p
                      style={{
                        color: "#FF9089",
                        marginTop: "-22px",
                      }}
                    >
                      You are not old enough to proceed further!
                    </p>
                  )}
                  {state.apiSuccess === 1 && (
                    <p style={{ color: "#00E060", fontSize: "24px" }}>
                      Approved!
                    </p>
                  )}
                  {state.apiSuccess === 1 && (
                    <button
                      type="button"
                      className="button"
                      disabled={state.isLoading}
                      onClick={handleClose}
                      style={{
                        backgroundColor: state.isLoading
                          ? "#77979A"
                          : "#1C5257",
                      }}
                    >
                      <span
                        className="button-text"
                        style={{
                          opacity: state.isLoading ? "0.5" : "1",
                        }}
                      >
                        Continue
                      </span>
                    </button>
                  )}


                  {state.apiSuccess === 0 && state.isScanDocument === 0 && (
                    <button
                      type="button"
                      className="button mobile-close"
                      disabled={state.isLoading}
                      onClick={handleClose}
                      style={{
                        backgroundColor: state.isLoading
                          ? "#77979A"
                          : "#1C5257",
                      }}
                    >
                      <span
                        className="button-text"
                        style={{
                          opacity: state.isLoading ? "0.5" : "1",
                        }}
                      >
                        Close
                      </span>
                    </button>
                  )}

                  {state.isScanDocument === 1 &&
                    state.scanDocumentPage === 0 &&
                    !selectedFile && (
                      <button
                        type="button"
                        className="button"
                        disabled={state.isLoading}
                        onClick={scanDocument}
                        style={{
                          backgroundColor: state.isLoading
                            ? "#77979A"
                            : "#1C5257",
                          margin: "auto",
                        }}
                      >
                        <span
                          className="button-text"
                          style={{
                            opacity: state.isLoading ? "0.5" : "1",
                          }}
                        >
                          Upload Document
                        </span>
                      </button>
                    )}
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 50, hide: 50 }}
                    overlay={renderTooltip}
                  >
                    <p style={{ fontSize: "14px" }}>
                      <a
                        href="mailto:support@voltox.ai"
                        style={{ textDecoration: "none" }}
                      >
                        Need Help?
                      </a>
                    </p>
                  </OverlayTrigger>
                </div>
                <div className="tag-name"></div>
                <div id="google_translate_element"></div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AgeEstimation;
