import React, { useEffect, useState } from 'react';
import './kyc.css'
import axios from 'axios';
import ModalPopup from './modal';
import socketIOClient from 'socket.io-client';



const FaceDetection = () => {
  const socket = socketIOClient('https://api.voltox.ai');
  const [livenessUrl, setLivenessUrl] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const token: any =
            urlParams.get("token") || "0c18c0a4-a5c4-4945-94f3-b30aaed884f3";
  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API_URL}/user/generate-liveness-url`, {
      "redirect_url": "https://customer.voltox.ai/select-document",
      "token":token
    })
    .then((res:any) => {
      console.log(res)
      if(res && res.data && res.data.settings.success){
        setLivenessUrl(res.data.data.liveness_url);
        localStorage.setItem('referenceId',res.data.data.reference_id)
      //  socket.emit('liveness-room',res.data.data.reference_id)
      }
      })
      .catch((error:any) => {
      });
   // setLivenessUrl('https://app.facia.ai/?66307e78e53921714454136');

   socket.on('liveness-accepted', (data:any) => {
    console.log('liveness-accepted==========>',data)
    const localRefrenceId = localStorage.getItem('referenceId');
    if(localRefrenceId === data.reference_id){
      window.location.href = "/select-document";
    }
   })
    
  }, []);

  return (
    <ModalPopup>
          <h2>Step 1</h2>
          <p>Please turn on your camera and place your head in the circle.</p>
          <form>
            <iframe
              src={livenessUrl}
              width="600"
              height="400"
              title="My Iframe"
              id="myiframe"
              allow="camera"
            ></iframe>
            <p style={{ fontSize: "14px" }}>Need Help?</p>
          </form>
    </ModalPopup>
  );
};
export default FaceDetection;
