import React, { useState } from "react";
import "./kyc.css";
import { Link } from "react-router-dom";
import ModalPopup from './modal';

const SelectDocument = () => {
  const [selectedButton, setSelectedButton] = useState("");
  const handleButtonClick = (buttonId: string) => {
    console.log(buttonId);
    setSelectedButton(buttonId);
  };
  return (
    <ModalPopup>
      <div className="identity-container">
        <h2>Step 2</h2>
        <p>Select a piece of ID to scan.</p>
        <button
          type="button"
          className={`identity-button ${
            selectedButton === "button1" ? "selected" : ""
          }`}
          style={{
            backgroundColor:
              selectedButton === "button1" ? "#E4FFF0" : "#EFEFEF",
            borderColor: selectedButton === "button1" ? "#35DC94" : "#D1D3D3",
          }}
          onClick={() => handleButtonClick("button1")}
        >
          <p style={{ color: "#767B7B", lineHeight: "24px" }}>Identity Card</p>
        </button>
        <p className="break-line">OR</p>
        <button
          type="button"
          className={`identity-button ${
            selectedButton === "button2" ? "selected" : ""
          }`}
          style={{
            backgroundColor:
              selectedButton === "button2" ? "#E4FFF0" : "#EFEFEF",
            borderColor: selectedButton === "button2" ? "#35DC94" : "#D1D3D3",
          }}
          onClick={() => handleButtonClick("button2")}
        >
          <p style={{ color: "#767B7B", lineHeight: "24px" }}>
            Driver's License
          </p>
        </button>
        <p className="break-line">OR</p>
        <button
          type="button"
          className={`identity-button ${
            selectedButton === "button3" ? "selected" : ""
          }`}
          style={{
            backgroundColor:
              selectedButton === "button3" ? "#E4FFF0" : "#EFEFEF",
            borderColor: selectedButton === "button3" ? "#35DC94" : "#D1D3D3",
          }}
          onClick={() => handleButtonClick("button3")}
        >
          <p style={{ color: "#767B7B", lineHeight: "24px" }}>Passport</p>
        </button>
        <p style={{ fontSize: "14px" }}>Need Help?</p>
        <Link to="/scan-document">
          <button
            type="button"
            className="button"
            disabled={!selectedButton}
            style={{
              backgroundColor: selectedButton ? "#1C5257" : "#77979A",
            }}
          >
            <span
              className="button-text"
              style={{
                opacity: selectedButton ? "1" : "0.5",
              }}
            >
              Select & Continue
            </span>
          </button>
        </Link>
      </div>
    </ModalPopup>
  );
};
export default SelectDocument;
