import React, { useEffect, useRef, useState } from 'react';
import './kyc.css'
import { Formik, Field, Form, ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import ModalPopup from './modal';


enum RegistrationStep {
  FaceContainer,
  Identity,
  ScanIdentity,
  VerifyInformation,
  EmailForm,
  ThankuPage
}

const ScanDocument = () => {
 const navigate = useNavigate();

  const fileRef = useRef<HTMLInputElement>(null);;
 
  const fileUploadValues = {
    files: ''
  };

  const fileUploadSchema = Yup.object({
    files: Yup.mixed()
      .required("Please upload a file")
      .test("is-file-too-big", "File exceeds 10MB", () => {
        let valid = true;
        const files = fileRef?.current?.files;
        if (files) {
          const fileArr = Array.from(files);
          fileArr.forEach((file: any) => {
            const size = file.size / 1024 / 1024;
            if (size > 10) {
              valid = false;
            }
          });
        }
        return valid;
      })
      .test(
        "is-file-of-correct-type",
        "File is not of supported type",
        () => {
          let valid = true;
          const files = fileRef?.current?.files;
          if (files) {
            const fileArr = Array.from(files);
            fileArr.forEach((file: any) => {
              const type = file.type.split("/")[1];
              const validTypes = [
                "zip",
                "xml",
                "xhtml+xml",
                "plain",
                "svg+xml",
                "rtf",
                "pdf",
                "jpeg",
                "png",
                "jpg",
                "ogg",
                "json",
                "html",
                "gif",
                "csv"
              ];
              if (!validTypes.includes(type)) {
                valid = false;
              }
            });
          }
          return valid;
        }
      )
  });

  const FileUpload = ({ fileRef, ...props }: any) => {
    const [field, meta] = useField(props);
    return (
      <div>
        <label htmlFor="formFile" className='form-label'><p className='label-text'>Upload File<span className='text-danger'>*</span></p></label>
        <input ref={fileRef} multiple={true} type='file' {...field} className="form-control"  id="formFile" />
        {meta.touched && meta.error ? (
          <div style={{ color: "red" }}>{meta.error}</div>
        ) : null}
      </div>
    );
  };



  const submitDocument= (values: any, { resetForm }: any) => {
    console.log('submit documnet successful!');
    //resetForm();
    navigate('/verify-detail');
  };



  return (
    <ModalPopup>
        <div className="scan-identity">
          <h2>Step 2</h2>
          <p>Please place your document inside the box.
            Make sure to have good lighting.</p>

          <Formik initialValues={fileUploadValues} validationSchema={fileUploadSchema} onSubmit={submitDocument}>
            <Form>
              <div>
                <FileUpload name="files" fileRef={fileRef} />
              </div>
              <div>
                <button type="submit" className='submit-btn'><span className='button-text'>Confirm & Continue</span></button>
              </div>
            </Form>
          </Formik>
          <Link to="/select-document"><button  className='scan-other' ><span className='button-text'>Select other ID</span></button></Link>
        </div>
   </ModalPopup>
  );
};
export default ScanDocument;
