import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

function HandleEvent() {
  console.log('inside webhook event1==============>')
  const location = useLocation();
  useEffect(() => {
    console.log('inside webhook event2==============>', location)
    if (location.state) {
      const { redirect_url, customer_id, customer_email, ttl } = location.state;
      // Handle the response
      console.log("Response from facia.ai:");
      console.log("Redirect URL:", redirect_url);
      console.log("Customer ID:", customer_id);
      console.log("Customer Email:", customer_email);
      console.log("TTL:", ttl);
      // You can further process the response as needed
    }
  }, [location]);

  // Render the webhook data or a loading message if the data isn't available yet
  return <div>Loading...</div>;
}
export default HandleEvent;
