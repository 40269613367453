// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.identity-button{
    margin-top: 24px;
    background-color: #1C5257;
    border-radius: 16px;
    border: 4px solid #D1D3D3;
    width: 350px;
    height: 88px;
}

.scan-other{
    margin-top: 24px;
    background-color: #1C5257;
    border-radius: 24px;
    border: none;
    width: 350px;
    height: 45px;
}

.submit-btn{
    margin-top: 24px;
    background-color: #1C5257;
    border-radius: 24px;
    border: none;
    width: 350px;
    height: 45px;
}

.error-message{
    color: red;
}
.error-border {
    border-color: red;
  }
  .form-control {
    padding: 0.532rem 0.75rem !important;
    color: #497579 !important;
    border-radius: 4px !important;
    border: 1px solid !important;
    margin-top: -16px 
}
.label-text{
    color: #1C5257 !important;
}


#myiframe .font-style-paragraph {
    display: none !important;
}

/* iframe {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    max-width: 1204px;
    width: 100%;
    height: 85vh;
    border: none;
  }

  .blur {
    background: #1f1e1e;
    overflow: hidden
  }
  .blurred{
    opacity: 0.2;
    z-index: -1;
    position: relative;
  } */`, "",{"version":3,"sources":["webpack://./src/components/kyc-process/kyc.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;AACA;IACI,iBAAiB;EACnB;EACA;IACE,oCAAoC;IACpC,yBAAyB;IACzB,6BAA6B;IAC7B,4BAA4B;IAC5B;AACJ;AACA;IACI,yBAAyB;AAC7B;;;AAGA;IACI,wBAAwB;AAC5B;;AAEA;;;;;;;;;;;;;;;;;;;;KAoBK","sourcesContent":["\n.identity-button{\n    margin-top: 24px;\n    background-color: #1C5257;\n    border-radius: 16px;\n    border: 4px solid #D1D3D3;\n    width: 350px;\n    height: 88px;\n}\n\n.scan-other{\n    margin-top: 24px;\n    background-color: #1C5257;\n    border-radius: 24px;\n    border: none;\n    width: 350px;\n    height: 45px;\n}\n\n.submit-btn{\n    margin-top: 24px;\n    background-color: #1C5257;\n    border-radius: 24px;\n    border: none;\n    width: 350px;\n    height: 45px;\n}\n\n.error-message{\n    color: red;\n}\n.error-border {\n    border-color: red;\n  }\n  .form-control {\n    padding: 0.532rem 0.75rem !important;\n    color: #497579 !important;\n    border-radius: 4px !important;\n    border: 1px solid !important;\n    margin-top: -16px \n}\n.label-text{\n    color: #1C5257 !important;\n}\n\n\n#myiframe .font-style-paragraph {\n    display: none !important;\n}\n\n/* iframe {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 1000;\n    max-width: 1204px;\n    width: 100%;\n    height: 85vh;\n    border: none;\n  }\n\n  .blur {\n    background: #1f1e1e;\n    overflow: hidden\n  }\n  .blurred{\n    opacity: 0.2;\n    z-index: -1;\n    position: relative;\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
