import React from 'react';
import { Modal } from "react-bootstrap";
import './modal.css'

const ModalPopup = ({ children }:any) => {
  return (
  <Modal
  show={true}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered
  backdrop="static"
  className="age-estimation-modal"
>
  <Modal.Body>
    <div className="container">
      <div className="mobile-hedaer">
        <img src="/assets/logo.svg" alt="login" className="mobile-logo" />
      </div>
      <div className="bg-white h-auto age-row">
        <div className="p-0 left-side-image">
          <img src="/assets/logo-bg.svg" alt="login" className="logo-bg" />
          <img src="/assets/logo.svg" alt="login" className="logo-icon" />
        </div>
        <div className="p-0 right-side age-estimation">
          <div className="pages">
            {children}
            <div className="tag-name"></div>
            <div id="google_translate_element"></div>
          </div>
        </div>
      </div>
    </div>
  </Modal.Body>
</Modal>
  );
};

export default ModalPopup;
