import React from 'react';
import './kyc.css'
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import ModalPopup from './modal';

const VerifyDetail = () => {
    const navigate = useNavigate();
  const detailValues={
    firstName:'Sonu',
    lastName:'Moraniya',
    nationality:'Indion',
    expirationDate:'2-2-2022',
    IDNumber:'ded2331wd'
  }

  const verifyDetail= (values: any, { resetForm }: any) => {
    console.log('verify information successful!');
    //resetForm();
    navigate('/registration-form');
  };

 

  return (
    <ModalPopup>
        <div className="verify-detail">
          <h2>Step 3</h2>
          <p>Please confirm your information.</p>
          <Formik initialValues={detailValues} onSubmit={verifyDetail}>
            {() => (
              <Form>
                <div className="row">
                  <div className="col">
                  <label htmlFor="firstName" className='form-label'><p className='label-text'>First Name</p></label>
                    <Field type="text" className="form-control" id="firstName" name="firstName" placeholder="First name" aria-label="First name" readOnly />
                  </div>
                  <div className="col">
                  <label htmlFor="lastName" className='form-label'><p className='label-text'>Last Name</p></label>
                    <Field type="text" id="lastName" name="lastName" className="form-control" placeholder="Last name" aria-label="Last name" readOnly />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                  <label htmlFor="nationality" className='form-label'><p className='label-text'>Nationality</p></label>
                  <Field type="text" id="nationality" name="nationality" className="form-control" placeholder="Nationality" readOnly />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                  <label htmlFor="IDNumber" className='form-label'><p className='label-text'>ID Number</p></label>
                  <Field type="text" id="IDNumber" name="IDNumber" className="form-control" placeholder="ID Number" readOnly/>
                  </div>
                  <div className="col">
                  <label htmlFor="expirationDate" className='form-label'><p className='label-text'>Expiration Date</p></label>
                  <Field type="text" id="expirationDate" name="expirationDate" className="form-control" placeholder="Expiration Date" readOnly />
                  </div>
                </div>
                <div>
                  <button type="submit" className='submit-btn'><span className='button-text'>Continue</span></button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        </ModalPopup>
  );
};
export default VerifyDetail;
