import React, { useEffect, useRef, useState } from 'react';
import './kyc.css'
import { Formik, Field, Form, ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import ModalPopup from './modal';



enum RegistrationStep {
  EmailForm,
  ThankuPage
}

const RegistrationForm = () => {
    const [step, setStep] = useState(RegistrationStep.EmailForm);
  const initialValues = {
    email: '',
  };
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
  })

  
  const onSubmit = (values: any, { resetForm }: any) => {
    console.log('Login successful!');
    //resetForm();
    setStep(RegistrationStep.ThankuPage)

    setTimeout(() => {
   // spoof.closeVideo();
    window.close();
    window.parent.postMessage({ type: 'Registration Done', data: null }, '*');
    }, 2000);
  };
  
  return (
    <ModalPopup>
   
      {step === RegistrationStep.EmailForm && (
        <div className="email-form">
          <h2>Step 4</h2>
          <p>Please enter your email address.
            It will be used for marketing purposes.</p>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched }) => (
              <Form>
                <div>
                  <label htmlFor="email" className='form-label'><p className='label-text'>Email<span className='text-danger'>*</span></p></label>
                  <Field type="email" placeholder="Email" id="email" name="email" className={`form-control ${errors.email && touched.email ? 'error-border' : ''}`} />
                  <ErrorMessage name="email" component="div" className='error-message' />
                </div>
                <div>
                  <button type="submit" className='submit-btn'><span className='button-text'>Confirm & Register</span></button>
                </div>
              </Form>
            )}
          </Formik>
          <p style={{ fontWeight: "700px", fontSize: "14px" }}>By registering, you’re agreeing to our <b>Terms & Conditions</b> and <b>Privacy Policy.</b><span style={{ color: "#FF2D55" }}>*</span></p>
        </div>
      )}
      {step === RegistrationStep.ThankuPage && (
        <div className="thanku-page">
          <div className='success-image'>
            <img src='/assets/verified.png' alt="verified" className="verified-image" />
          </div>
          <h2 style={{ width: "500px" }}>Thank you for using Voltox!</h2>
          <p style={{ marginLeft: "96px" }}> You have successfully registered!</p>
        </div>
      )}

</ModalPopup>
  );
};
export default RegistrationForm;
