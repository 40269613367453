import './App.css';
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import ThankuPage from './components/thankuPage';

import Registration from './components/RegistrationForm';
import Login from './components/login';
import FaceDetection from './components/kyc-process/face-detection';
import SelectDocument from './components/kyc-process/select-document';
import ScanDocument from './components/kyc-process/scan-document';
import VerifyDetail from './components/kyc-process/verify-detail';
import RegistrationForm from './components/kyc-process/registration';
import HandleEvent from './components/kyc-process/handle-event';
import AgeEstimation from './components/age-estimation.controller';
import ModalPopup from './components/kyc-process/modal';
import IframeContainer from './components/kyc-process/iframe-modal';


function App() {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');

  // const location = useLocation();
  // const pathKey = location.pathname.split('/')[1]; // This will give you the key 'select-document' when the URL is '/select-document'
  // if(pathKey === 'select-document'){
           
  // }
 
  return (
    <div  className={`container-fluid w-100 p-0 h-100 fullscreen`}>
          <Router>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
              <Route path="/select-document" element={<SelectDocument />} />
              {/* <Route path="/select-document" element={<IframeContainer route="/select-document" />} /> */}
              <Route path="/scan-document" element={<ScanDocument />} />
              <Route path="/verify-detail" element={<VerifyDetail />} />
              <Route path="/registration-form" element={<RegistrationForm />} />
              <Route path="/thanku" element={<ThankuPage />} />
              <Route path="/face-event" element={<HandleEvent />} />
              <Route path="/" element={id === 'login' ? <Login /> : id === 'registration' ? <FaceDetection /> : <AgeEstimation />} />
              {/* <Route path="/login" element={id === 'login' ? <Login /> : null} />
              <Route path="/registration" element={id === 'registration' ? <FaceDetection /> : null} /> */}
              {/* <Route path="/face-detection" element={<FaceDetection />} /> */}
              {/* <Route path="/" element={<AgeEstimation />} /> */}
              </Routes>
            </Suspense>
          </Router>
    </div>
  );
}

export default App;